// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'UserNavMenu',
  mixins: [uiMixin],
  methods: {
    /**
     * Close user sessions
     */
    handleCloseSession() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          this.closeSession()
        },
        actionButtonText: 'Cerrar',
        text: '¿Estas seguro que deseas cerrar la sesión?',
        type: 'warning',
        visible: true
      })
    }
  }
}
