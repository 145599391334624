// Utils
import { getPathImage } from '@/utils'

export default {
  name: 'HeaderNavMenu',
  computed: {
    /**
     * Obtiene la URL del logo del la marca
     *
     * @return {string}
     */
    brandImage() {
      return getPathImage('brand.png', true)
    }
  }
}
