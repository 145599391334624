// Vuex
import { mapGetters, mapMutations } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'AppNotifications',
  mixins: [uiMixin],
  data() {
    return {
      toggleNotificacion: false,
      toggleSpeedDial: false,
      // Opciones para 'fab-btn'
      vFabButtonOptions: {
        color: 'red darken-1',
        dark: true,
        fab: true,
        small: true
      },
      // Opciones para 'v-sheet'
      vSheetOptions: {
        color: 'white',
        rounded: true,
        elevation: 3,
        width: 250
      },
      // Opciones peara 'v-speed-dial'
      vSpeedDialOptions: {
        direction: 'left',
        right: true,
        transition: 'slide-x-reverse-transition',
        bottom: true
      }
    }
  },
  computed: {
    ...mapGetters('app', ['notificationsData'])
  },
  watch: {
    notificationsData: {
      handler(value) {
        // Mostramos u ocultamos "Notificaciones"
        this.toggleNotificacion = Boolean(
          !isNil(value) &&
            Array.isArray(value) &&
            value.length &&
            (this.hasProPackage || this.hasAdvancePackage)
        )
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['removeAppNotification']),
    /**
     * Handle click on item
     *
     * @param {Number} index - index inside list items
     */
    handleClickMenuItem(index) {
      if (index > -1) {
        // Procedemos a la acción de la notificación,
        // por ahora solo redirigimos a una ruta de la APP
        const location = get(this.notificationsData, `${index}.location`, null)
        this.routerPushTo(location)
        // Eliminamos notificación del state
        this.removeAppNotification(index)
      }
    }
  }
}
