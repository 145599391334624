// Components
import UserMenu from '../../elements/users/UserNavMenu'
import AppNavMenu from '../AppNavMenu'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'AppBar',
  model: {
    prop: 'visible',
    event: 'change'
  },
  components: { AppNavMenu, UserMenu },
  props: {
    /**
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    navBarVOptions: {
      default() {
        return {
          app: true,
          color: 'white',
          dark: false,
          dense: true,
          'elevate-on-scroll': true,
          elevation: '1'
        }
      },
      type: Object
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      places: []
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice', 'mediumDevice', 'largeDevice']),
    /**
     * Opciones de la "barra de navegación"
     *
     * @return {Object}
     */
    currentNavBarVOptions() {
      return {
        ...this.navBarVOptions
      }
    },
    /**
     * Mostramos botón de "menú"?
     *
     * @return {Boolean}
     */
    showMenuButton() {
      return this.mediumDevice || this.extraSmallDevice
    }
  },
  watch: {
    largeDevice: {
      handler(value) {
        this.$emit('change', value)
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Click on menu button
     */
    handleClickMenu() {
      this.$emit('change', !this.visible)
    }
  }
}
