// Constants
import { ROLES } from '@/constants'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, cloneDeep } from 'lodash'

export default {
  name: 'DefaultNavMenu',
  data() {
    return {
      links: [
        {
          icon: 'mdi-view-dashboard',
          label: 'Panel de control',
          name: 'dashboard',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-store',
          label: 'Establecimientos',
          name: 'places-list',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-silverware',
          label: 'Cartas / Menús',
          name: 'menus',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-file-edit',
          label: 'Activaciones',
          name: 'activations-list',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-qrcode-scan',
          label: 'Pedidos pegatinas',
          name: 'sticker-orders-list',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-account-multiple',
          label: 'Usuarios',
          name: 'users-list',
          roles: [ROLES.brand_admin.value]
        },
        {
          icon: 'mdi-cog',
          label: 'Configuración',
          name: 'config-main',
          roles: [ROLES.brand_admin.value]
        }
      ],
      linksValidated: []
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos/establecemos todos los datos necesarios
     * del componente
     */
    getEveryNeededData() {
      this.linksValidated = this.parseLinks(this.links)
    },
    /**
     * Se recorre de forma recursiva todos los links y los childs
     * definiendo el path y validando si se tiene los roles y
     * los addons necesarios
     */
    parseLinks(links) {
      // Se obtiene todas las rutas
      const allRoutes = get(this, '$router.options.routes[0].children', [])

      return links.reduce((sumLinks, link) => {
        // Enlace que vamos a "personalizar"
        const customLink = cloneDeep(link)
        // Limitaciones del enlace
        const linkRoles = get(customLink, 'roles', false)
        // Condiciones del enlace
        const haveLinkRole = !linkRoles ? true : linkRoles.indexOf(this.userData.role) > -1

        // Si es visible por "brand" y por "role"
        if (haveLinkRole) {
          // Obtenemos la ruta actual, si esta existe en "routesName"
          const currentRoute = allRoutes.find((route) => route.name === customLink.name)
          // Incluimos el path del "enrutador" al link
          if (currentRoute && currentRoute.path) {
            customLink.path = {
              name: currentRoute.name,
              params: customLink.params
            }
          }

          if (customLink.divider || customLink.action || customLink.href) {
            // No se trata de un enlace (al menos no uno interno)
            sumLinks.push(customLink)
          } else if (customLink.childs) {
            // recursividad con los hijos
            sumLinks.push(customLink)
            customLink.childs = this.parseLinks(customLink.childs, allRoutes)
          } else if (customLink && customLink.path) {
            // Se trata de un enlace (ruta interna)
            sumLinks.push(customLink)
          }
        }

        return sumLinks
      }, [])
    }
  }
}
