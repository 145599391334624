// Components
import AppBar from '@/components/ui/AppBar'
import AppNavDrawer from '@/components/ui/AppNavDrawer'
import AppNavMenu from '@/components/ui/AppNavMenu'
import DefaultNavMenu from '../DefaultNavMenu'
import HeaderNavMenu from '@/components/ui/HeaderNavMenu'

export default {
  name: 'DefaultNavBar',
  components: {
    AppBar,
    AppNavDrawer,
    AppNavMenu,
    DefaultNavMenu,
    HeaderNavMenu
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    /**
     * Change the visibility of navDrawer
     *
     * @param {Boolean} show - show or hide navDrawer
     */
    handleVisibility(show) {
      this.visible = show
    }
  }
}
