// Components
import AppNotifications from '@/components/elements/store/AppNotifications'
import DefaultNavBar from '@/components/elements/layout/default/DefaultNavBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'DefaultLayout',
  components: { AppNotifications, DefaultNavBar },
  mixins: [uiMixin],
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('company', ['companyData'])
  },
  watch: {
    companyData: {
      async handler(value) {
        const companyId = get(value, 'id', null)

        if (!isNil(companyId)) {
          // Show loading
          this.modifyAppLoadingLayer({
            visible: true
          })
          try {
            await this.setRationsInStore() // Raciones
          } catch (error) {
            this.modifyAppAlert({
              text: error.message,
              type: 'error',
              visible: true
            })
          } finally {
            // Hide loading
            this.modifyAppLoadingLayer({
              visible: false
            })
          }
        }
      },
      immediate: true
    },
    userData: {
      async handler(newData, oldData) {
        const newUserId = get(newData, 'id', null)
        const oldUserId = get(oldData, 'id', null)

        if (!isNil(newUserId) && newUserId !== oldUserId) {
          // Show loading
          this.modifyAppLoadingLayer({ visible: true })
          try {
            await this.setCompanyData() // Compañía
          } catch (error) {
            this.modifyAppAlert({
              text: error.message,
              type: 'error',
              visible: true
            })
          } finally {
            // Hide loading
            this.modifyAppLoadingLayer({ visible: false })
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['getCompanyData']),
    ...mapActions('meta', ['getEveryRationsDataByCompanyId']),
    ...mapActions('config', ['getEveryAllergens', 'getEveryLanguages']),
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      try {
        // Show loading
        this.modifyAppLoadingLayer({
          visible: true
        })

        await this.setAllergensInStore() // Alérgenos
        await this.setLanguagesInStore() // Idiomas
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Show loading
        this.modifyAppLoadingLayer({
          visible: false
        })
      }
    },
    /**
     * Establecemos en el Store los datos de la compañía
     */
    async setCompanyData() {
      const companies = Object.keys(get(this.userData, 'companies', {}))

      if (!companies.length) {
        throw new Error(
          'No existe ninguna compañía relacionada a este usuario, comuníquese con el proveedor del servicio'
        )
      }

      await this.getCompanyData(companies[0])
    },
    /**
     * Establecemos en el Store los alégernos (todos)
     */
    async setAllergensInStore() {
      await this.getEveryAllergens()
    },
    /**
     * Establecemos en el Store los idiomas (todos)
     */
    async setLanguagesInStore() {
      await this.getEveryLanguages()
    },
    /**
     * Establecemos en el Store las raciones de la compañía
     */
    async setRationsInStore() {
      await this.getEveryRationsDataByCompanyId(this.companyData.id)
    }
  }
}
