export default {
  name: 'AppNavDrawer',
  props: {
    toggleMenu: {
      type: Boolean,
      default: false
    },
    // Propertis v-navigation-drawer -->
    // https://vuetifyjs.com/en/components/navigation-drawers/#navigation-drawers
    navDrawerVOptions: {
      default() {
        return {
          app: true,
          fixed: true,
          'disable-resize-watcher': true,
          left: true,
          'overlay-opacity': 0.15
        }
      },
      type: Object
    }
  },
  computed: {
    /**
     * Get options to NavDrawer
     *
     * @return {Object} options to nav drawer
     */
    getOptionsNavDrawer() {
      return this.navDrawerVOptions
    }
  },
  methods: {
    /**
     * Change the value (visible) to show or hide dialog
     *
     * @param {Boolean} value - hide or show
     */
    handleInput(value) {
      this.$emit('onChangeVisibility', value)
    }
  }
}
